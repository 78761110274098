.tab {
  font-size: 14px;
  padding: 10px;
  border-bottom-width: 3px;

  &[data-active] {
    font-weight: 600;
    border-image: linear-gradient(
        to right,
        var(--tabs-color) 50%,
        transparent 50%
      )
      1;
  }
}

.tab:hover {
  border-image: linear-gradient(
      to right,
      var(--tab-border-color) 50%,
      transparent 50%
    )
    1;

  &[data-active] {
    border-image: linear-gradient(
        to right,
        var(--tabs-color) 50%,
        transparent 50%
      )
      1;
  }
}

.list::before {
  border-width: 0px;
}
