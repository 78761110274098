.workflowItem {
  border-radius: var(--mantine-radius-default);
  border: 1px solid var(--mantine-color-gray-2);
}

.workflowEditedItem {
  animation: changedWorkflowAnimation 1.5s ease-in-out infinite;
}

@keyframes changedWorkflowAnimation {
  0% {
    border-radius: var(--mantine-radius-default);
    border: 1px solid var(--mantine-color-gray-2);
    box-shadow: none;
  }
  50% {
    border: 1px solid var(--mantine-color-teal-4);
    box-shadow: 0px 0px 10px 0px #38d9a94d;
  }
  100% {
    border-radius: var(--mantine-radius-default);
    border: 1px solid var(--mantine-color-gray-2);
    box-shadow: none;
  }
}
