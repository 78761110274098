.workflowsTable {
  margin-left: 12px;
  margin-right: 12px;
  outline: none;
  padding-block: 5px;
}

.workflowsTableRow {
  background-color: var(--mantine-color-white);
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
}

.workflowsTableEditedRow {
}

.workflowsTableEditedRow:has(.edited-action-buttons) {
  background-color: var(--mantine-color-white);
  margin-bottom: 6px;
  outline: none;
  border-radius: 4px;
  border: 1px solid transparent;
  animation: changedWorkflowAnimation 1.5s ease-in-out infinite;
}

.workflowsTableHeaderRow {
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
}

@keyframes changedWorkflowAnimation {
  0% {
    border: 1px solid transparent;
    box-shadow: none;
  }
  50% {
    border: 1px solid var(--mantine-color-teal-4);
    box-shadow: 0px 0px 10px 0px #38d9a94d;
  }
  100% {
    border: 1px solid transparent;
    box-shadow: none;
  }
}

.workflowsTableRowExpanded {
  box-sizing: border-box;
  background-color: var(--mantine-color-white);
  margin-top: -6px;
  border-radius: 4px;
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
}

.workflowsTableRowExpandedInner {
  padding-block: 4px;
  border: 1px solid transparent;
}

.workflowsTableSelectedRow {
  border: 1px solid var(--mantine-color-teal-4);
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px #38d9a94d;
  z-index: 1;
}

.workflowsTableSelectedExpandedRow {
  border: 1px solid var(--mantine-color-teal-4);
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #38d9a94d;
  padding-left: 0px;
}

.workflowsHoverRow {
}

.workflowsHoverRow:hover {
  border: 1px solid var(--mantine-color-teal-2);
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #38d9a94d;
}
